@import "@bosch/frontend.kit-npm/dist/frontend-kit.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
.splashContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}

.splashIndicator {
  display: flex;
  justify-content: center;
}

@media (width < 768px) {
  .desktop-only {
    display: none;
  }
}
@media (width > 768px) {
  .mobile-only {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL25ldC9ybmd2bTAxMDAxL2ZzMC9qZW5raW5zLXNsYXZlL3dvcmtzcGFjZS9QTV9keGZfaW5zaWRlX3BvcnRhbC1ucG1fZGV2ZWxvcC9zcmMvc3R5bGVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ1E7QUFFUjtBQUNBO0FBQ0E7QUFHQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtJQUNFOzs7QUFJSjtFQUNFO0lBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlIFwic3JjL2Fzc2V0cy92YXJpYWJsZXNcIiBhcyAqO1xuQGltcG9ydCBcIkBib3NjaC9mcm9udGVuZC5raXQtbnBtL2Rpc3QvZnJvbnRlbmQta2l0LmNzc1wiO1xuXG5AdGFpbHdpbmQgYmFzZTtcbkB0YWlsd2luZCBjb21wb25lbnRzO1xuQHRhaWx3aW5kIHV0aWxpdGllcztcblxuXG4uc3BsYXNoQ29udGFpbmVyIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDUwJTtcbiAgbGVmdDogNTAlO1xuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgtNTAlLCAtNTAlKTtcbiAgd2lkdGg6IDMwMHB4O1xufVxuXG4uc3BsYXNoSW5kaWNhdG9yIHtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG59XG5cbkBtZWRpYSAod2lkdGggPCAkYnJlYWtwb2ludCkge1xuICAuZGVza3RvcC1vbmx5IHtcbiAgICBkaXNwbGF5OiBub25lO1xuICB9XG59XG5cbkBtZWRpYSAod2lkdGggPiAkYnJlYWtwb2ludCkge1xuICAubW9iaWxlLW9ubHkge1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gIH1cbn1cbiJdfQ== */