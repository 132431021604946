@use "src/assets/variables" as *;
@import "@bosch/frontend.kit-npm/dist/frontend-kit.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


.splashContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}

.splashIndicator {
  display: flex;
  justify-content: center;
}

@media (width < $breakpoint) {
  .desktop-only {
    display: none;
  }
}

@media (width > $breakpoint) {
  .mobile-only {
    display: none;
  }
}
